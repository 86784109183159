.project-contents {
    display: block;
    width: 100%;
    height: 100%;
    padding: 25px;
}

.project-contents.mobile {
    height: auto;
    overflow: hidden;
}

.typist-container {
    margin: 20px auto 0 5%;
    font-family: "Consolas", monospace;
    font-size: 2vw;
    font-weight: bolder;
    height: auto;
    width: 100%;
    display: flex;
    align-items: center;
    text-align: left;
}

.typist-container.mobile {
    font-size: 4vw;
}