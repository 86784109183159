.resume-wrapper {
    position: center;
    width: 90%;
    height: calc(75%);
    display: block;
    /*margin-top: 4%;*/
    margin-right: auto;
    margin-left: auto;
    z-index: 0;
    background-color: white;
    border-style: ridge;
    padding: 20px;
}