.icon-container {
    width: 100%;
    justify-content: center;
    height: auto;
    /*margin-top: 5%;*/
    position: fixed;
    bottom: 5vh;
    display: flex;
    margin: auto;
    align-items: baseline;
    /*margin-bottom: 5%;*/
}

.home-icon {
    fill: black;
    transition: 1s;
    padding: 5px;
    width: 50px;
    height: 50px;
}

.home-icon:hover {
    fill: crimson;
    transition: 1s;
}

.home-icon:active {
    fill: white;
}

.animate__animated.animate__fadeIn {
    --animate-duration: 2s;
}