.content-container {
    position: center;
    width: 75%;
    height: 75%;
    display: flex;
    margin-top: 4%;
    margin-right: auto;
    margin-left: auto;
    z-index: -4;
    background-color: rgba(255, 255, 255, 0.9);
    border-style: none;
    padding: 16px;
}

.content-container.mobile {
    height: auto;
    display: block;
    overflow: auto;
    margin-bottom: 4%;
    width: 80%;
    padding: 15px;
}