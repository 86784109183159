.animation-test {

    transition: 1s;
    border-radius: 100px;
    background-color: black;
    padding: 10px;

}

.animation-test:hover {

    transition: 1s;
    background-color: crimson;

}