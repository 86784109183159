.nav-wrapper {
    position: sticky;
    height: 5vh;
    width: 100%;
    background-color: white;
    z-index: 5;
    display: flex;
    min-height: 5px;
}

.nav-wrapper.mobile {
    height: 5vh;
    /*background-color: transparent;*/
}

.nav-name {
    height: 3vw;
    /*width: 30vw;*/
    margin-left: 1vw;
    /*margin-right: 0;*/
    margin-top: 1vh;
    float: left;
}

/*@media (prefers-color-scheme: dark) {*/
/*    .nav-name {*/
/*        filter: invert(100%);*/
/*    }*/
/*}*/

.nav-name.mobile {
    float: none;
    margin: auto;
    height: 3vh;
    position: center;
}

.menu-button {
    float: left;
    /*padding: 10px;*/
    /*margin-top: auto;*/
    /*margin-bottom: auto;*/
    position: absolute;
    padding: 10px;
    margin: auto;
    height: auto;
    align-content: center;
    top: 10%;
    bottom: 10%;

}

.scroll-links {
    /*margin-left: 10%;*/
    /*float: right;*/
    width: 50%;
    margin: auto auto 0;
    /*margin: auto;*/
}

.nav-links {
    display: flex;
    height: auto;
    width: 100%;
    /*margin-bottom: 5px;*/
    margin-top: auto;
    letter-spacing: 2px;
    font-family: "Poiret One", sans-serif;
    font-weight: bold;
    /*text-shadow: 1px 0 black;*/
    font-size: 1vw;
    overflow: visible;
}

.nav-link {
    transition: 1s;
    color: black !important;
    text-decoration: none;
    position: relative;
    /*bottom: 15%;*/
    user-select: none;
    margin: auto -10px auto;
}

/*.nav-slash {*/
/*    transition: 1s;*/
/*    color: black;*/
/*    text-decoration: none;*/
/*    position: relative;*/
/*    bottom: -10%;*/
/*    user-select: none;*/
/*}*/

.nav-link:hover {
    transition: 1s;
    color: crimson !important;
}

.selected {
    transition: 1s;
    color: crimson !important;
    text-shadow: 2px 0 black;
    pointer-events: none;
}

.selected.blog {
    pointer-events: auto;
}

.drawer {
    position: absolute;
    width: 100%;
    height: 0;
    /*padding: 0 20px;*/
    z-index: 5;
    font-family: "Poiret One", sans-serif;
    font-weight: bolder;
    font-size: 4vw;
    text-align: center;
}

.drawer.closed {
    background-color: white;
    transition: 1s;
    height: 0;
}

.drawer.open {
    background-color: white;
    transition: 1s;
    height: 45vh;
}

.drawer-links-container {
    height: auto;
    position: relative;
}


.drawer-link {

}

.animate__animated.animate__fadeIn {
    --animate-duration: 2s;
}