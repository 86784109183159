.desktop-page {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;

    /*background-position: center;*/
    /*background-size: cover;*/
    /*background-repeat: no-repeat;*/
    overflow: hidden;
}