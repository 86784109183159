.about-container {
    display: flex;
    width: 100%;
    padding: 25px;
}

.about-container.mobile {
    display: block;
}

.picture-me {
    width: calc(50% - 25px);
    height: 100%;
}

.picture-me.mobile {
    width: 100%;
}

.img-container {
    justify-items: center;
    align-items: center;
    display: flex;
    margin: auto;
    height: 100%;
    padding: 20px;
}

.portrait {
    width: 20vw;
    margin: auto;
}

.about-text {
    width: calc(50% -  25px);
    height: auto;
    max-height: 100%;
    margin: auto;
    font-family: "Lora", serif;
    font-size: 100%;
    display: flex;
    overflow: auto;
}

.about-text.mobile {
    width: 100%;
    font-size: 3vw;
}

.about-typist-container {
    margin: auto;
    font-family: "Consolas", monospace;
    font-size: 1.5vw;
    height: auto;
    font-weight: bolder;
}

.about-typist-container.mobile {
    font-size: 2.75vw;
    width: 100%;
}

.about-text-container {
    /*margin-top: 2vw;*/
    width: 100%;
    height: auto;
}

.emoji {
    /*list-style: none;*/
    padding: 0;
    margin: 0;
    list-style-position: outside;
}

.emoji ul {
    /*padding-left: 1rem;*/
    /*text-indent: -0.75rem;*/
    list-style-position: outside !important;
    margin-left: 1em;
}

.emoji li:nth-of-type(1) {
    list-style-type: "👨‍💻 ";
    display: list-item;
    list-style-position: outside;
    margin-left: 2em;
}
.emoji li:nth-of-type(2) {
    list-style-type: "🎓 ";
    display: list-item;
    list-style-position: outside;
    margin-left: 2em;
}


.emoji li:nth-of-type(3) {
    list-style-type: "🤖 ";
    display: list-item;
    list-style-position: outside;
    margin-left: 2em;
}

.emoji li:nth-of-type(4) {
    list-style-type: "🎮 ";
    display: list-item;
    list-style-position: outside;
    margin-left: 2em;
}

.emoji li:nth-of-type(5) {
    list-style-type: "🤓 ";
    display: list-item;
    list-style-position: outside;
    margin-left: 2em;
}

