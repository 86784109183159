.contact-content {
    display: flex;
    width: 100%;
    padding: 25px;
    height: 100%;
}

.contact-content.mobile {
    display: block;
}

.left-content {
    width: 50%;
    margin: auto;
    text-align: center;
    display: block;
    font-size: 1vw;
    font-weight: bold;
    font-family: "Lora", serif;
}

.left-content.mobile {
    width: 100%;
}

.contact-typist-container {
    font-family: "Consolas", monospace;
    font-size: 200%;
    height: auto;
    font-weight: bolder;
    margin: auto auto 2%;
}

.contact-typist-container.mobile {
    font-size: 4vw;
    width: 100%;
}

.contact-header {
    font-size: 150%;
    margin: 2% auto 0;
}

.contact-header.mobile {
    font-size: 300%;
}

.contact-body {
    font-weight: normal;
    font-size: 120%;
    margin: 2% auto 0;
    width: 75%;
}

.contact-body.mobile {
    font-size: 250%;
    margin-bottom: 25px;
    width: 100%;
}

.right-content {
    width: 50%;
    display: block;
    position: center;
    margin: auto;
    height: auto;
    max-height: 100%;
}

.right-content.mobile {
    width: 100%;
    overflow: hidden;
}