.name {
    display: block;
    height: auto;
    position: center;
    width: auto;
    max-width: 80%;
    user-select: none;
    margin: 25vh auto 5vh;
}

/*@media (prefers-color-scheme: dark) {*/
/*    .name {*/
/*        filter: invert(100%);*/
/*    }*/
/*}*/

.name.mobile {
    margin: 10vh auto 10vh;
}

.backgroundVideo {
    /*transform: translate(-50%, 0);*/
    position: absolute;
    justify-content: center;
    min-width: 100vw;
    min-height: 100vh;
    height: 100vh;
    width: auto;
    user-select: none;
    z-index: -5;
    --animate-duration: 5s;
    object-fit: cover;
    --animate-delay: 6s;
    display: block;
    margin-left: auto;
    margin-right: auto;

}

.backgroundVideo.mobile {
    transform: translate(-50%, 0);
}