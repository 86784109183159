.projects-container {
    overflow: auto;
    position: center;
    width: 90%;
    height: 75%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    /*grid-auto-rows: auto;*/
    grid-gap: 20px 10px;
    /*row-gap: 150px;*/
    margin-right: auto;
    margin-left: auto;
    z-index: 0;
    /*padding: 30px;*/
}

.projects-container.mobile {
    width: 100%;
    height: auto;
    align-content: start;
    align-items: start;
    row-gap: 20px;
    grid-template-columns: repeat(auto-fit, minmax(50vw, 1fr));
}

.project-cell {
    transition: 1s;
    border-style: solid;
    width: auto;
    height: 200px;
    padding: 15px;
    font-family: "Lora", serif;
    font-size: 14px;
    color: black;
    text-decoration: none;
    align-self: start;

}

.project-cell:hover {
    transition: 1s;
    color: black;
    box-shadow: 5px 5px crimson;
}

.project-title {
    pointer-events: none;
    user-select: none;
    text-decoration: none;
}

.project-timeframe {
    float: left;
    pointer-events: none;
    user-select: none;
}

.project-description {
    margin-top: 1.5vh;
    pointer-events: none;
    user-select: none;
}

.project-link {
    height: 8vw;
    text-decoration: none !important;
}
