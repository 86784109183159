.form-container {
    width: 90%;
    position: center;
    display: block;
    margin: auto;
    align-self: center;
    height: 100%;
    /*background-color: green;*/
}

#form-contents {
    height: auto;
    margin: auto;
    display: block;
    position: center;
}

#nameInput {
    display: flex;
    width: 100%;
}

#emailInput {
    display: flex;
    width: 100%;
}

#subjectInput {
    display: flex;
    width: 100%;
}

#contact-text-input {
    width: 100%;
    display: flex;
}

.submit-button {
    width: 100%;
}