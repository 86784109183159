.page {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.page-enter {
  opacity: 0;
  transform: scale(1);
}

.page-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 500ms, transform 500ms;
}

.page-exit {
  opacity: 1;
  transform: scale(1);
}

.page-exit-active {
  opacity: 0;
  transform: scale(1);
  transition: opacity 500ms, transform 500ms;
}

.page-wrapper {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 95vh;
  overflow: hidden;
}

/*:root {*/
/*  color-scheme: dark light;*/
/*}*/